:root {
    --chart-blue: #226dff;
    --chart-line-gray: #dfdfdf;
}

.chart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 16px;
    padding-left: 0;
    padding-right: 0;
    color: #333333;
    font-family: Inter;
    font-feature-settings: "liga" off;
}

.chart-header {
    color: var(--chart-blue);
    font-size: 1.25rem;
    font-style: normal;
    line-height: 24px;
    margin: 0;
}

.radio-group {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.radio-group > label {
    display: flex;
    gap: 8px;
    margin: 0;
}

.field {
    display: flex;
    height: 76px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.field > input {
    display: flex;
    padding: 10px 8px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-sizing: border-box;
}

.chart-table-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    text-align: center;
}

.data-table {
    width: 100%;
    table-layout: fixed;
    border: 1px solid var(--line-gray);
    border-radius: 4px 4px 0 0;
    border-bottom: none;
    overflow: hidden;
    box-sizing: border-box;
}

.data-table > thead {
    background-color: #eaeaea;
    font-weight: normal;
}

.data-table > thead > tr > th {
    color: #333333;
    font-weight: 500;
    padding: 8px;
    border: 1px solid var(--line-gray);
    border-top: none;
    border-bottom: none;
    letter-spacing: -0.176px;
    line-height: 150%;
    overflow: auto;
}
.data-table > thead > tr > th:first-child {
    border-top-left-radius: 4px;
}

.data-table > thead > tr > th:last-child {
    border-top-right-radius: 4px;
}

.data-table > tbody > tr > td > input {
    width: 100%;
    border: 0px solid transparent;
    text-align: center;
    padding: 8px;
    background-color: white;
    border: 1px solid rgb(235, 233, 233);
    color: #333333;
}

.add-button {
    width: 100%;
    color: white;
    border: solid transparent;
    background-color: var(--chart-blue);
    border: none;
    border-radius: 0px 0px 4px 4px;
    font-size: 28px;
}

.footer {
    display: flex;
    justify-content: end;
    width: 100%;
}

#delete {
    color: #4f4f4f;
    font-size: 1rem;
    line-height: 24px; /* 150% */
    border: transparent;
    background-color: transparent;
}

#delete:hover {
    color: var(--chart-blue);
}
