@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.custom-dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa !important;
    border-color: #ced4da !important;
    color: #9F9999 !important;
    border-radius: 4px;
    padding: 8px 12px;
    margin-right: 7px;
    margin-bottom: 5px;
    font-size: 16px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus {
    border-color: #0A1D7C !important;
    background-color: #f8f9fa !important;
}

.custom-dropdown-toggle.active {
    border-color: #0056b3 !important;
    background-color: #f8f9fa !important;
    color: #000000 !important;
}

.custom-dropdown-icon {
    margin-left: 8px;
    pointer-events: none;
}

.dropdown-toggle::after {
    display: none !important;
}

.input-group-text.search {
    background-color: #FAFBFC;
    border: 1px solid #ced4da;
    border-radius: 4px 0 0 4px;
    padding: 0.375rem 0.75rem;
    border-right: none;
    margin-left: 10px;
    margin-bottom: 0px;
}

.input-group-text .search {
    width: 15px;
    height: 15px;
    vertical-align: middle;
}

.input-group .form-control.search-bar {
    flex: 1;
    max-width: 100%;
    background-color: #FAFBFC;
    border-radius: 0 4px 4px 0;
    border-left: none;
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.275rem 0.15rem;
    margin-bottom: 0;
}

.right-container {
    display: flex;
    align-items: center;
}

.col {
    align-items: center;
}

.custom-checkbox input[type="checkbox"]:hover {
    border: #0A1D7C !important;
}

.custom-checkbox input[type="checkbox"]:active {
    background-color: #0056b3 !important;
}

.custom-padder {
    padding-left: 20px;
    margin-bottom: 8px;
    margin-top: 10px;
}

.container2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.astext {
    background: none !important;
    border: none !important;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: #9F9999 !important;
    font-size: 16px !important;
}

.subMenu {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    background: none !important;
    border: none !important;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: black !important;
    font-size: 16px !important;
    width: 100%;
}

.subMenu:hover {
    background: rgba(19, 70, 170, 0.06) !important;
}

.astext:hover {
    color: black !important;
}

@media screen {
    .search-bar {
        display: flex;
        justify-content: space-evenly;
        margin-right: 30px;
        align-items: center;
    }
}
