.sectionButton {
    width: 232px;
    height: 56px;
    padding: 0px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    margin-bottom: 12px;
}

.sectionButton:hover {
    background-color: #f0f0f0;
}

.deleteButton {
    background-color: #f2f2f2;
    width: 144px;
    height: 39px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
}
.deleteButton:hover {
    background-color: #e0e0e0;
}

.text {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 20px;
}

.editableLabel {
    margin-left: 5px;
    max-width: 400px;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: translateY(2.5px);
}

.editableLabel::-webkit-scrollbar {
    height: 5px;
}

.editableLabel::-webkit-scrollbar-track {
    background-color: transparent;
}
.editableLabel::-webkit-scrollbar-thumb {
    border: 15px solid transparent;
    border-radius: 4px;
    background-color: #a9a9a9;
}
.editableLabel::-webkit-scrollbar-thumb:hover {
    background-color: gray;
}
/* .card {

} */
.card::-webkit-scrollbar {
    width: 5px;
}
.card::-webkit-scrollbar-track {
    background-color: transparent;
}
.card::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a9a9a9;
}
.card::-webkit-scrollbar-thumb:hover {
    background-color: gray;
}