.event-info-container {
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .event-description {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 12px;
  }
  
  