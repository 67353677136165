.collapsible .content {
    overflow: hidden;
    transition: max-height 0.5s ease;
    width: 100%;
}

.collapsible .content.open {
    /* max-height: 1000px; */
    overflow: scroll;
}

.container {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-break: auto;
    padding: 16px;
    align-items: center;
}

.collapsible {
    text-align: left;
    margin: 1rem;
    box-shadow: 0px 2px 5px #00000040;
    border-radius: 4px;
    overflow: hidden;
}

.collapsible .title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    font-weight: bold;
    background: rgba(19, 70, 170, 0.06);
    border: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 100%;
}
