@font-face {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 400;
    src:
        local("Avenir LT Pro 45 Book"),
        local("AvenirLTPro-Book"),
        url(https://fonts.gstatic.com/l/font?kit=ijwUs5PnXdA4ZZUA55d-&skey=8887f17a62203d44&v=v3)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 900;
    src: url(//db.onlinewebfonts.com/c/901497541657a2f24e42848bcf7fad52?family=Avenir+Heavy);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 700;
    src: url(//db.onlinewebfonts.com/c/901497541657a2f24e42848bcf7fad52?family=Avenir+Heavy);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family:
        "Avenir",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
}

#map {
    height: 100%;
}

html,
body {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
}

html {
    overflow-y: hidden;
}

h2 {
    font-weight: 700;
}

@media only screen and (max-width: 900px) {
    h1 {
        font-size: 1.8em;
    }
}

#auth-root {
    margin-top: 10px;
}

.box {
    position: relative;
    z-index: 1;
    background-color: white;
    border-radius: 5px;
    padding: 20px 20px 40px 20px;
    box-shadow: 0 0 10px rgba(3, 215, 252, 0.2);
    /*Blue*/
}

.splash {
    position: absolute;
    top: 0;
    left: calc(50% - 300px);
    width: 600px;
    height: 600px;
    background-image: url("../img/login-hero-01.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.fixed-container {
    padding: 10px;
    padding-top: 0px;
}

.no-span {
    display: table;
    white-space: nowrap;
}

.containerInfo {
    display: flex;
}

.map-google-map {
    width: 100vw;
    height: calc(100vh - 70px);
    pointer-events: auto;
}

.map-list {
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background: white;
    padding-top: 10px;
}

.map-list::-webkit-scrollbar {
    display: none;
}
/* .map-cell {
    border-top: solid #e0e0e0;
    background-color: white;
    padding-top: 18px;
    padding-bottom: 18px;
    transition: border-left 0.2s;
}

.map-overlay {
    height: calc(60vh - 12px);
}


.map-cell:hover {
    
} */

.map-overlay {
    height: calc(60vh - 12px);
}

.map-cell {
    position: relative;
    padding-top: 10px;
    border-top: solid #e0e0e0;
    background-color: white;
    padding: 18px;
    transition: border-left 0.2s;
    overflow: hidden;
    z-index: 1;
    min-height: 100px; 
}

.map-cell::before {
    content: ''; 
    position: absolute; 
    top: 10px; 
    left: 8px; 
    right: 8px; 
    bottom: 8px; 
    background-color: #f4f4f4; 
    border-radius: 8px; 
    opacity: 0; 
    transition: opacity 0.3s; 
    z-index: -1; 
}

.map-cell:hover::before {
    opacity: 1; 
}
.scroll-indicator {
    margin-bottom: 12px;
    border-radius: 10px;
    height: 5px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    background-color: #e0e0e0;
}

.cell-container {
    padding-bottom: 100px;
}

.scroll-indicator:hover {
    background-color: gray;
}

.map-list.sticky {
    overflow-y: scroll;
}

.cell-container.sticky {
    max-height: 100%;
    overflow-y: scroll;
}

.padder {
    padding-left: 12px;
    padding-right: 12px;
}
.padder::-webkit-scrollbar {
    display: none;
}
.point {
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 20px;
    border-color: white;
    margin-left: 10px;
    border-radius: 5px !important;
}

.point h2 {
    font-size: 18px;
}

.filter-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    transition: all 400ms;
}

.form-wrapper {
    background-color: white;
    padding: 20px;
    height: 100%;
}

.tag {
    border: solid 1px;
    border-radius: 2px;
    padding: 0 8px 3px 8px;
    display: inline-block;
    margin-top: 8px;
    margin-right: 5px;
}

.clear-all {
    padding: 7px 8px 5px 8px;
}

.clear-all:hover {
    opacity: 0.75;
    cursor: pointer;
}

.remove-tag {
    opacity: 1;
    margin-left: 5px;
    font-size: 22px;
}

.remove-tag:hover {
    opacity: 0.75;
}

.step-wrapper {
    padding: 20px;
}

.admin-provider {
    background: white;
    border-radius: 20px;
    border-color: white;
    position: relative;
    flex: 2;
}

@-webkit-keyframes translate {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-60px);
    }
}

@keyframes translate {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-60px);
    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.translate {
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: translate;
    animation-name: translate;
}

.fade-out {
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fade-out;
    animation-name: fade-out;
}

.fade-in {
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
}

.hide {
    opacity: 0;
}

.react-bootstrap-table th {
    background-color: #f8f9fa;
    border: 0;
}

.react-bootstrap-table tbody tr:first-child td {
    border-top: 0;
}

@media only screen and (min-width: 900px) {
    .scroll-container {
        max-height: calc(100vh - 90px);
        overflow-y: scroll;
        position: relative;
        z-index: 1;
    }
    .list-wrapper {
        border-right: solid 6px #f8f9fa;
    }
}

.row-spaced {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.primary-slider {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: #F5F6FA;
    border-radius: 8px;
    gap: 4px;
    width: 60%;
}

.primary-slider-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    width: 55%;
    margin: 0.5rem;
    margin-bottom: 0;
}

.primary-slider-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
    margin: .3rem;
    margin-right: 2rem;
}

.primary-slider-desription {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #828282;
    margin: 0;
    margin-left: .5rem;
}
.template-header {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0px;
}

.template-header-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #333333;
    margin: 0;
}

.template-header-desription {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #4F4F4F;
    margin: 0;
}

.row-nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: hidden;
}

.template-add {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 4px;
    background: #f1f4fa;
    border: 2px dashed #226DFF;
    border-radius: 4px;
    color: #226DFF;
    width: 100%;
    position: sticky;
    bottom: -10px;
    z-index: 2;
}

.reverse-column {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
}

/*Loading Spinner, Temporary*/

.spinner-wrap {
    width: 100%;
    padding-top: 50px;
}

.category-delete-container {
    display: flex;
    justify-content: end;
}

.category-delete-button {
    cursor: pointer;
}

.spinner {
    font-size: 10px;
    margin: 0px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(
        left,
        #0069d9 10%,
        rgba(0, 105, 217, 0) 42%
    );
    background: -webkit-linear-gradient(
        left,
        #0069d9 10%,
        rgba(0, 105, 217, 0) 42%
    );
    background: -o-linear-gradient(left, #0069d9 10%, rgba(0, 105, 217, 0) 42%);
    background: -ms-linear-gradient(
        left,
        #0069d9 10%,
        rgba(0, 105, 217, 0) 42%
    );
    background: linear-gradient(
        to right,
        #0069d9 10%,
        rgba(0, 105, 217, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before {
    width: 50%;
    height: 50%;
    background: #0069d9;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
}

.spinner:after {
    background: #f8f9fa;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader {
    display: inline-block;
    border: 2px solid transparent;
    border-top: 2px solid white;
    border-radius: 50%;
    margin-left: -16px;
    margin-right: 2px;
    margin-bottom: -2px;
    width: 16px;
    height: 16px;
    animation: spin 800ms linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.myModal {
    width: 70% !important;
    max-width: 70% !important;
}

.provider-cell-image {
    height: 100px;
    width: 100px;
    object-fit: contain;
    transition: filter 100ms;
}

.blur {
    filter: blur(1px);
}

.modalMobile {
    height: 100vh;
    width: 100vw;
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
}

.modal-col-flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modalImage {
    padding-left: 30px;
    padding-top: 20px;
}

.modal-hours {
    padding-bottom: 15px;
    padding-left: 24px;
    display: flex;
    flex-direction: row;
}

.modal-image-col {
    height: 50%;
}

.modal-hours-container {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding-left: 0px;
    width: fit-content;
    white-space: nowrap;
}

.modal-hours-container h5 {
    font-size: 14px;
    font-weight: bold;
}

.modal-center-text {
    text-align: center;
}

.modal-content {
    border-radius: 0;
    border-color: transparent;
}

.modal-col-flex-end {
    font-weight: 600;
    color: #4f4f4f;
}

.modal-col-flex-start {
    display: flex;
    justify-content: flex-start;
}

.modal-text {
    display: inline;
}

.modal-card-text {
    display: flex;
    width: 100%;
    flex-direction: row;
    font-size: 13px;
    padding-bottom: 10px;
}

.modal-hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.modal-body {
    padding-left: 2rem;
    padding-right: 2rem;
}

.close {
    color: #fff;
    opacity: 1;
    font-size: 50px !important;
    font-weight: 100 !important;
    padding-top: 0.3rem !important;
    padding-bottom: 1rem !important;
}

.image-cover {
    display: flex;
    position: absolute;
    z-index: 100;
    right: 0;
    flex-direction: row-reverse;
    padding: 15px 2rem;
    padding-bottom: 5px;
    border-radius: 5px;
}

.modalHeader {
    font-size: 13px;
}

.modalHeader h5 {
    font-size: 15px;
}

.image-cover h2 {
    color: white;
    padding-left: 2vw;
    font-size: 20px;
}

.desc-Box {
    padding-top: 20px;
}

.desc-Box p {
    font-size: 13px;
}

.desc-Box h3 {
    font-size: 20px;
    font-weight: 700;
}

.view-container {
    overflow: hidden;
    width: 95%;
    height: calc(100vh - 56px);
    height: -moz-calc(100vh - 56px);
    height: -webkit-calc(100vh - 56px);
    padding-left: 15px;
    padding-right: 15px;
}

.switch-view-button {
    margin: 0 15px;
}

.view-row {
    height: 85%;
    margin-left: 0;
    margin-right: 0;
}

.list-view-text-body {
    margin-bottom: 0;
}

.map-view {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.label {
    background-color: #56ccf2 !important;
    border-radius: 14px !important;
    padding: 4px 13px !important;
    margin-right: 5px !important;
    font-weight: bold !important;
    font-size: 14px;
}

.not-found-oops {
    color: black;
    /* Fallback: assume this color ON TOP of image */
    background-image: url("../img/blue-stars.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 15em;
}

.modal-map {
    position: fixed;
    z-index: 0;
    padding: 0;
    margin: 0;
    left: 0;
    top: 65px;
}

.modal-info {
    overflow-y: scroll;
    padding: 0vh 4vw;
}

.mobile-cell-image {
    margin-top: 10px;
    width: 100vw;
    height: 190px;
    object-fit: cover;
}

.image-upload {
    width: 2rem;
    height: 2rem;
}

.modal-mobile-card {
    z-index: 2;
    position: absolute;
    padding: 0 8vw;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 100vw;
    left: 0;
    background-color: white;
    margin-top: 150px;
}

.admin-list-container {
    width: 30vw;
}

.add-export-bttns-wrapper {
    width: 30vw;
    display: flex;
    flex-direction: row;
}

.add-button-wrapper {
    padding: 8px;
    width: 100%;
}

.add-button-wrapper .btn {
    padding: 12px;
    font-weight: bold;
}

.export-button-wrapper {
    padding: 8px;
    width: 10vw;
}

.export-button-wrapper .btn {
    padding: 12px;
    font-weight: bold;
}

.dashboard-content {
    padding-left: 86px;
}

.admin-dashboard {
    display: flex;
    flex-direction: row;
}

#root {
    position: fixed;
    z-index: 1000;
}

#sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 75px;
    padding-bottom: 8px;
    padding-left: 15px;
    height: 100vh;
    width: 90px;
    background: #1a4f97;
    transition: all 500ms;
    box-shadow:
        23.8596px 5.61404px 50px rgba(0, 0, 0, 0.02),
        35.0877px 0px 70px rgba(86, 128, 248, 0.05),
        14.0351px 0px 25px rgba(86, 128, 248, 0.03);
}

#sidebar:hover {
    width: 230px;
}

.logo:hover + #sidebar {
    width: 230px;
}

.none {
    display: none;
    transition: all 1000ms;
}

#content {
    padding: 30px;
    background-color: azure;
}

.cell {
    color: white;
    transition: all 300ms;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cell a {
    color: white;
    text-decoration: none;
}

.cell:hover {
    border-left: white solid 5px;
    cursor: pointer;
    height: 60px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo {
    position: fixed;
    padding-top: 10px;
    width: 60px;
    height: 60px;
    transition: padding-left 200ms;
}

.logo.expanded {
    padding-left: 60px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 60px;
    height: 60px;
    font-size: 18px;
    border-radius: 30px;
    position: relative;
}

.cell-title {
    opacity: 1;
    font-size: 14px;
    transition: all 300ms;
}

.gray-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(196, 196, 196, 0.4);
    z-index: 10;
}

.fadeIn {
    animation: fadeIn ease 300ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (min-width: 768px) {
    /*Desktop Specific*/
    #auth-root {
        margin-top: 30px;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
    .step-wrapper {
        padding: 20px 0 20px 60px;
    }
    .map-list {
        padding-top: 0;
        transition: width 200ms;
    }
    .modal-hours-container {
        font-size: 16px;
        padding-left: 0px;
    }
    .desc-Box p {
        font-size: 16px;
    }
    .desc-Box h3 {
        font-size: 33px;
    }
    .modal-card-text {
        font-size: 16px;
    }
    .modalHeader {
        font-size: 16px;
    }
    .modalHeader h5 {
        font-weight: 700;
        font-size: 20px;
    }
    .modal-hours-container h5 {
        font-size: 18px;
    }
    .modal-content {
        border-radius: 20px;
    }
    #map {
        transition: width 200ms;
        position: relative;
    }
    .cell-container {
        padding-bottom: 30px;
    }
}

.overflow-y-auto {
    overflow-y: auto;
    overflow-x: hidden;
}

.btn.btn-primary.btn-block.point {
    display: block;
    text-align: center;
    width: 100px;
    background: #007bff;
}

.underline {
    border-bottom: solid gray 1px;
}

#template-root {
    overflow-y: scroll;
    height: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
}

.category-cell-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 12px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}


.category-grip {
    font-size: 27px;
    color: #4f4f4f;
    transition: all 200ms;
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.primary-grip {
    font-size: 27px;
    color: #4f4f4f;
    transition: all 200ms;
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;}

.category-cell-wrapper:hover .category-grip {
    background: #226DFF;
}

.category-cell-wrapper:hover .primary-grip {
    background: #E0E0E0;
}

.category-cell-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 0 5px 5px 0;
    padding: 8px 12px;
    transition: border-left 200ms;
}

.category-cell-wrapper:hover .category-button-add {
    background: #226DFF !important;
    border-color: #226DFF !important;
}

.category-button-add {
    background: #BDBDBD !important;
    border-color: #BDBDBD !important;
}

.category-cell-head-title {
    background-color: #FAFBFC !important;
    margin-right: 1rem !important;
}

.category-cell-head-select{
    background-color: #FAFBFC !important;
}

.template-title {
    border-bottom: 2px solid #D9D9D9;
    width: 100%;
    color: #333333;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
}

.category-button-button {
    font-size: 22;
    border-radius: 0;
    padding: 0;
    background-color: white !important;
    border: hidden;
    cursor: pointer;
    align-self: flex-end;
    margin-left: 2.5rem;
}

.add-option {
    margin-top: 8px;
    width: 60%;
}

.options-wrapper {
    height: auto !important;
    min-width: 200px;
    max-width: fit-content;
    margin-top: 8px;
    color: gray;
}

.options-item {
    flex-direction: row;
}

.options-item-1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.options-color {
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: 3px !important;
    border-radius: 50% !important;
    border: hidden !important;
    cursor: pointer;
    overflow: hidden;
}

.options-add-color {
    width: 3.5rem !important;
    height: 2.5rem !important;
    padding: 1px !important;
    border: hidden !important;
    margin-right: px !important;
    cursor: pointer;
    overflow: hidden;
}

.options-label {
    display: inline-block;
    position: relative;
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.options-label:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    box-shadow: 0 1px gray;
    margin-top: -0.8em;
    background: black;
    transform: scaleX(0);
    transform-origin: center left;
    animation: strikethrough 1s 0.5s cubic-bezier(0.55, 0, 0.1, 1) 1;
    transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.options-close {
    margin-left: 6px;
    background-color: white;
    transition: color 200ms;
    cursor: pointer;
}

.options-close:hover {
    color: red;
}

.options-item .options-close:hover + .options-label:after {
    transform: scaleX(1);
}

.category-button-column {
    display: flex;
    flex-direction: column;
}

.ReactCollapse--collapse {
    transition: height 300ms;
}

#fallback-root {
    padding: 20px 10vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    height: 100vh;
}

#fallback-image {
    width: 100%;
    max-height: 60vh;
    object-fit: contain;
}

#fallback-action-container {
    max-width: 100%;
    width: 700px;
    padding: 12px;
    text-align: center;
}

#about-root {
    height: 100vh;
    width: 100vw;
}

.team-marker {
    position: relative;
    z-index: 0;
}

.team-marker-image {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: solid lightgreen 2px;
    transition: border-radius 100ms;
    transition: transform 200ms;
    transform: scale(1);
    transform-origin: bottom;
    object-fit: cover;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
}

.team-marker.active {
    z-index: 2;
}

.team-marker:hover {
    z-index: 1;
}

.team-marker:hover .team-marker-image {
    transform: scale(1.2);
}

.team-marker.active .team-marker-image {
    border-radius: 0;
    transform: scale(4);
    box-shadow: none;
}

.team-marker.active .team-marker-link {
    color: #2867b2;
}

.team-marker-label {
    display: none;
    font-size: 0.8rem;
    background-color: #f8f9fa;
    width: 100px;
}

.team-marker:hover .team-marker-label {
    display: inherit;
    transform: translateX(-25px);
    text-align: center;
}

.team-marker-content {
    opacity: 0;
    padding: 8px 12px;
    width: 50px;
    height: 0;
    color: white;
    background-color: white;
    transition: opacity 100ms;
    transform: translateX(-75px);
}

.team-marker.active .team-marker-content {
    opacity: 1;
    width: 200px;
    color: black;
    height: auto;
}

#discussion-root {
    margin-top: 12px;
    margin-bottom: 20px;
    }

#discussion-scroll {
    display: flex;
    flex-direction: column-reverse;
    max-height: 440px;
    width: 60vw;
    overflow-y: scroll;
}

#discussion-scroll::-webkit-scrollbar {
    display: none;
}

.chat-bubble {
    border: solid 8px limegreen;
    padding: 10px 24px;
    width: 34vw;
    border-radius: 30px;
}

.chat-message {
    font-size: 18px;
    overflow-wrap: break-word;
}

.chat-time {
    font-size: 12px;
    color: gray;
}

.veil {
    position: relative;
    z-index: 1;
    background: linear-gradient(white, rgba(255, 255, 255, 0));
    width: 100%;
    height: 30px;
    margin-bottom: -30px;
}

.redDot {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
}

#homepage-root {
    background-color: white;
    padding: 30px 5vw;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}

#head {
    align-items: center;
    margin-bottom: 20px;
}

#head-logo {
    font-size: 1em;
}

#head-logo img {
    width: 120px;
}

#homepage-body {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
}

#homepage-background {
    position: absolute;
    top: -300px;
    right: -400px;
    width: 800px;
}

#homepage-background img {
    object-fit: contain;
    width: inherit;
    height: inherit;
}

@media only screen and (min-width: 900px) {
    #head-logo {
        font-size: 1.4em;
    }
    #head-logo img {
        width: 180px;
    }
    #head .btn {
        min-width: 120px;
    }
    #homepage-body {
        margin-top: 12vh;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .homepage-col {
        width: 45%;
    }
    #homepage-background {
        top: -300px;
        right: -500px;
        width: 1000px;
    }
}

#youtube {
    box-shadow: 4px 0 8px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.homepage-cta {
    background-color: #eee;
}

#signup .signup-root {
    background-color: rgb(239, 239, 239, 0.9);
    padding: 30px 5vw;
    margin-top: 12vh;
    height: 100vh;
    width: 100vw;
}

#signup .container {
    height: 20%;
}

.create-map-title {
    padding-top: 100px;
    font-size: 200%;
    font-weight: bold;
}

#create-map-content {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 10rem;
    width: 40rem;
    text-align: center;
}

#signup .button {
    margin-top: 5%;
    height: 2rem;
    width: 100%;
    font-size: 65%;
}

#signup .square {
    margin: 0.5rem;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 0 10px lightgray;
    border-radius: 10px;
}

#signup .title-text-styles {
    font-size: 100%;
    font-weight: bold;
    text-align: center;
}

#signup .body-text-styles {
    margin-top: 10%;
    font-size: 80%;
    color: grey;
    text-align: left;
    margin-left: 10px;
}

#signup .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    height: 10rem;
    width: 40rem;
    text-align: center;
}

#signup .button-1 {
    margin-left: 150px;
    height: 30px;
    width: 200px;
    font-size: 10px;
}

#signup .form-group {
    margin-top: 20px;
    font-size: 15px;
    height: 70%;
    width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

#signup .title-text {
    margin-top: 30px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

#signup .body-text {
    margin-top: 10px;
    font-size: 15px;
    text-align: center;
}

#signup .alert {
    margin-top: 0px;
}

#signup .confirm-text {
    margin-top: 25vh;
    text-align: center;
}

#signup .button-2 {
    margin-top: 50px;
    height: 30px;
    width: 200px;
    font-size: 10px;
}

.dropzone {
    text-align: center;
    padding: 100px;
    border: 3px dotted #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
}

.dropzone-mappicker {
    width: 12rem;
    height: 2rem;
    padding: 10px;
    text-align: center;
    background-color: #226dff;
    color: white;
    font-size: 16px;
    border-radius: 4px;
}

.imageModalSave {
    text-align: center;
    justify-content: center;
    margin-bottom: auto;
}

.saveButton {
    margin: 10px 10px;
    width: 5rem;
}

.cancelButton {
    margin: 10px 10px;
}

.align-items {
    display: flex;
    align-items: flex-start;
    height: 50%;
    margin-bottom: 5px;
}

.rowheight {
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.settingURLandLogo {
    display: flex;
    justify-items: start;
    gap: 20px;
}

.uploadButtonWidth {
    width: 30vw;
}

.urlFormandLogoTitle {
    display: flex;
    gap: 625px;
}

.chooseDefaultLocationText {
    text-align: left;
    margin-left: 0%;
    padding: 30px;
}

.custom-color {
    font-size: 12px;
    text-align: center;
    margin-top: 50%;
    margin-bottom: 50%;
    font-weight: bold;
}

.upload-restrict {
    max-height: 3vw;
    max-width: 3vw;
    margin-top: 35px;
}

.provider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
}

.provider-entry {
    height: 120px;
    padding: 10px;
}
