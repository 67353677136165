.directory::-webkit-scrollbar {
    width: 5px;
}
.directory::-webkit-scrollbar-track {
    background-color: transparent;
}
.directory::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #a9a9a9;
}
.directory::-webkit-scrollbar-thumb:hover {
    background-color: gray;
}

.addButton {
    border: 1px solid #226dff;
    background: transparent;
    color: #226dff;
    font-size: 16px;
    width: 100px;
    height: 40px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 4px;
}
